<template>
  <piepkaart :minLoadDataZoom="10" name="Speeltoestellen" :legend="legend">
    <template v-slot="{ bounds, minLoadDataZoom }">
      <piepkaart-wfs-layer
        :bounds="bounds"
        :minLoadDataZoom="minLoadDataZoom"
        wfs-url="https://geo2.piepkaart.nl/geoserver/piepkaart/wfs"
        type-name="piepkaart:speeltoestellen"
        output-format="json"
        srs-name="EPSG:4326"
        :pointToLayer="pointToLayer"
        :minRefetchDistance="100"
        :use-proxy="false"
      ></piepkaart-wfs-layer>
    </template>
  </piepkaart>
</template>
<script>
import Piepkaart from "@/components/Piepkaart.vue";
import PiepkaartWfsLayer from "@/components/PiepkaartWfsLayer.vue";
import L from "leaflet";
export default {
  name: "Speeltoestellen",
  components: {
    Piepkaart,
    PiepkaartWfsLayer,
  },
  data() {
    return {
      legend: [{ color: "#3B82F6", label: "Speeltoestel" }],
    };
  },
  methods: {
    pointToLayer(feature, latlng) {
      return L.circleMarker(latlng, {
        radius: 6,
        stroke: true,
        weight: 1,
        color: "#fff",
        fillColor: "#3B82F6",
        fillOpacity: 1,
      });
    },
  },
};
</script>
